/* HEADER */

header {
    background: var(--primary-bg);
    position: sticky;
    top: 0;
    z-index: 9;
}

header .navbar-brand img{
    max-width: 230px;
}
    
    header ul li:not(:last-child) {
        margin-right: 30px;
    }
    
    header ul li a{
        color: #fff !important;
    }
    .collapse:not(.show){
        display: none !important;
    }
    /* Desktop toggle */    
    .user-logged .navbar-toggler {
        align-self: flex-end;
        background: transparent;
        border: 0;
        border-radius: 50px 50px 0 0;
        height: 80px;
        padding: 0;
        width: 60px;
    }
    .user-logged .navbar-toggler {
        align-self: flex-end;
        background: transparent;
        border: 0;
        border-radius: 50px 50px 0 0;
        /* height: 48px; */
        padding: 0;
        width: 60px;
        display: block;
    }
    
    ul.navbar-dropdown{
        flex-direction: column !important;
        width: 100%;
    }
    
    .navbar-collapse-div{
        z-index: 9999;
    }

    .user-logged .navbar-toggler span {
        background: #808ce3;
        border-radius: 50px;
        display: block;
        height: 3px;
        margin: 0 auto;
        position: relative;
        transition: all .15s linear;
        width: 30px;
    }
    
    .user-logged .navbar-toggler span:after, .user-logged .navbar-toggler span:before {
        background: #808ce3;
        border-radius: 50px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        transition: all .15s linear;
        width: 100%;
    }
    
    .user-logged .navbar-toggler span:before {
        top: -6px;
    }
    
    .user-logged .navbar-toggler span:after {
        top: 6px;
    }
    
    .user-logged .navbar-collapse{
        position: absolute;
        right: 0px;
     background: #6c499d;
        top: 88%;
        max-width: 300px;
        width: 100%;
        border-radius: 10px 0px 10px 10px;
     
    }
    
    .user-logged .navbar-toggler:focus{
        box-shadow: none;
    }
    
    .user-logged .nav-item{
        margin-right: 0 !important;
    }
    /* .navbar-dropdown .nav-item a{
        text-indent: 20px !important;
    } */
    .user-logged .navbar-nav >li:last-child a{
        border-bottom: none;
    }
    
    
    .user-logged .profile-dropdown .nav-item > a {
        padding: 15px 20px;
        font-weight: 500;
        border-bottom: 1px solid rgba(255,255,255,0.30);
        transition: all .15s linear;
    }

    .user-logged .profile-dropdown .nav-item > a:hover{
        padding-left: 25px !important;
    }
    
    .navbar-brand + ul.navbar-nav  a:hover{
        color:var(--light-bg) !important;
    }
    
  

    .user-logged .navbar-toggler.togglemenu, .user-logged .navbar-toggler.togglemenu span{
             background: #6c499d !important;
    }
    
    .user-logged .navbar-toggler.togglemenu span:after, .user-logged .navbar-toggler.togglemenu span:before{
       background: #fff;
    }
    
    .user-logged .togglemenu span:after {
        top: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    
    .user-logged .togglemenu span:before {
        background-color: #fff;
        top: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    
    .user-logged .togglemenu span{
        background: #6c499d !important;
    }
    
    .custom-menu {
        display: flex;
        margin-left: auto;
        flex-direction: row;
    }
    
    .user-logged .custom-menu > li{
        margin-right: 30px !important;
    }
    
    .custom-menu a{
        border: none !important;
        padding: 0 !important;
    }

    .logged-user img{
        width: 50px;
        height: 50px;
        --border-rounded:50px;
        margin-right: 30px;
    }